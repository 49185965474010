import "bootstrap";
import 'bootstrap/dist/css/bootstrap.css' // Import precompiled Bootstrap css
import '@fortawesome/fontawesome-free/css/all.css'
import 'ol/ol.css';
import './css/site.css';

import Router from 'vue-router'
import Vue from 'vue';
import Login from './components/Login';
import Settings from './components/settings/Settings';
import BasicVisualizationList from './components/visualizations/VisualizationList';
import VisualizationView from './components/visualizations/VisualizationView';

// import AdminVisualizationList from './components/settings/visualizations/AdminVisualizationList';
// import AdminVisualizationAdd from './components/settings/visualizations/AdminVisualizationAdd';
// import AdminVisualizationDetail from './components/settings/visualizations/AdminVisualizationDetail';
// import AdminInspectionDetail from './components/settings/visualizations/AdminInspectionDetail';
import GroupAdd from './components/settings/groups/GroupAdd';
import GroupDetail from './components/settings/groups/GroupDetail';
import GroupList from './components/settings/groups/GroupList';

// import AdminProjectDetail from './components/settings/projects/AdminProjectDetail';
// import ProjectList from './components/settings/projects/AdminProjectList';

import UserAdd from './components/settings/users/UserAdd';
import UserDetail from './components/settings/users/UserDetail';
import UserList from './components/settings/users/UserList';

import VisualizationList from './components/settings/visualizations/VisualizationList';
import VisualizationDetail from './components/settings/visualizations/VisualizationDetail';
import VisualizationAdd from './components/settings/visualizations/VisualizationAdd';

import DataTableDetail from './components/settings/datatables/DataTableDetail';
import DataTableList from './components/settings/datatables/DataTableList';

import LayerList from './components/settings/layers/LayerList';
import LayerDetail from './components/settings/layers/LayerDetail';
import LayerAdd from './components/settings/layers/LayerAdd';
// import Tools from './components/tools/Tools';
// import IndexeddbList from './components/tools/indexeddb/IndexeddbList';
// import CacheList from './components/tools/cache/CacheList';

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login
        },
        {
            path: '/visualizations',
            name: 'visualizations',
            component: BasicVisualizationList
        },
        {
            path: '/settings',
            name: "settings",
            component: Settings,
            props: true,
            children: [
                {
                    path: 'users',
                    name: "users",
                    component: UserList
                },
                {
                    path: 'groups',
                    name: "groups",
                    component: GroupList
                },
                {
                    path: 'visualizations',
                    name: "visualizations",
                    component: VisualizationList
                },
                {
                    path: 'datatables',
                    name: "datatables",
                    component: DataTableList
                },
                {
                    path: 'layers',
                    name: "layers",
                    component: LayerList
                }
            ]
        },
        // USERS 
        {
            path: '/useradd',
            name: 'useradd',
            component: UserAdd
        },
        {
            path: '/userdetail/:id',
            name: 'userdetail',
            component: UserDetail,
            props: true
        },
        //GROUPS - SETTINGS
        {
            path: '/groupadd',
            name: 'groupadd',
            component: GroupAdd
        },
        {
            path: '/groupdetail/:id',
            name: 'groupdetail',
            component: GroupDetail,
            props: true
        },
        //ADMIN - DATA TABLES
        {
            path: '/visualizationdetail/:id',
            name: 'visualizationdetail',
            component: VisualizationDetail,
            props: true
        },
        {
            path: '/visualizationview/:id',
            name: 'visualizationview',
            component: VisualizationView,
            props: true
        },
        {
            path: '/visualizationadd',
            name: 'visualizationadd',
            component: VisualizationAdd,
            props: true
        },
        {
            path: '/datatabledetail/:id',
            name: 'datatabledetail',
            component: DataTableDetail,
            props: true
        },
        {
            path: '/layerdetail/:id',
            name: 'layerdetail',
            component: LayerDetail,
            props: true
        },
        {
            path: '/layeradd',
            name: 'layeradd',
            component: LayerAdd,
            props: true
        },
        //   {
        //     path: '/admininspectiondetail/:id',
        //     name: 'admininspectiondetail',
        //     component: AdminInspectionDetail,
        //     props: true
        // },
        // {
        //     path: '/visualizationadd',
        //     name: 'visualizationadd',
        //     component: AdminVisualizationAdd
        // },
        // {
        //     path: '/adminvisualizationdetail/:id',
        //     name: 'adminvisualizationdetail',
        //     component: AdminVisualizationDetail,
        //     props: true
        // },
        // //DATABASE
        // {
        //     path: '/tools',
        //     name: "tools",
        //     component: Tools,
        //     props: true,
        //     children: [
        //         {
        //             path: 'indexeddb',
        //             name: "indexeddb",
        //             component: IndexeddbList
        //         },
        //         {
        //             path: 'cache',
        //             name: "cache",
        //             component: CacheList
        //         }
        //     ]
        // },
        // //INSPECTION
        // {
        //     path: '/inspectiondetail/:id',
        //     name: 'inspectiondetail',
        //     component: InspectionDetail,
        //     props: true
        // },
        // {
        //     path: '/inspectiontool/:id',
        //     name: 'inspectiontool',
        //     component: InspectionTool,
        //     props: true
        // },
        // {
        //     path: '/inspectionview/:id',
        //     name: 'inspectionview',
        //     component: InspectionView,
        //     props: true
        // },
        // //GROUPS - SETTINGS
        // {
        //     path: '/groupadd',
        //     name: 'groupadd',
        //     component: GroupAdd
        // },
        // {
        //     path: '/groupdetail/:id',
        //     name: 'groupdetail',
        //     component: GroupDetail,
        //     props: true
        // },
        // //ADMIN - FORMS
        // {
        //     path: '/formadd',
        //     name: 'formadd',
        //     component: FormAdd
        // },
        // {
        //     path: '/formdetail/:id',
        //     name: 'formdetail',
        //     component: FormDetail,
        //     props: true
        // },
        // //ADMIN - SETTINGS
        // {
        //     path: '/projectadd',
        //     name: 'projectadd',
        //     component: AdminProjectAdd
        // },
        // {
        //     path: '/projectdetail/:id',
        //     name: 'projectdetail',
        //     component: AdminProjectDetail,
        //     props: true
        // },
      
        // {
        //     path: '/projectview/:id',
        //     name: 'projectview',
        //     component: AdminProjectView,
        //     props: true
        // }
    ]
})
if (!window.indexedDB) {
    console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
}
