<template>
	 <div class="container py-5">
        <h2>{{ $t('visualization') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'visualizations' }">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/visualizations'">
                        {{ $t('visualizations') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    {{ data.name }}
                </li>
            </ol>
        </nav>
        <div class="row">
            <div class="form-group col-md-6">
                <label for="name" required>{{ $t('name') }}</label>
                <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('enterVisualizationName')"><br/>
            </div>
            <div class="form-group col-md-6">
                <label for="center">{{ $t('center') }}</label>
                <input v-model="data.center" type="text" class="form-control" id="center" placeholder="Enter coordinates">
            </div>
        </div>
        <div v-if="groups.length > 0" class="form-group row">
            <label class="col-sm-12 col-form-label"><b>Groups</b></label>
            <div v-for="(item, i) in groups" :key="i" class="input-group col-md-4" style="padding-bottom:10px">
                <label readonly :for="item.id" type="text" class="form-control" :title="item.name" aria-label="Text input with checkbox">{{item.name.length > 12 ? item.name.substring(0, 12) + "..." : item.name}}</label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="checkbox" :id="item.id" @change="checkedGroup(item, $event)" :checked="groupAtProject(item)" aria-label="Checkbox for following text input">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12" style="display:block; text-align:right">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown">
                        <div class="btn-group">
                            <button id="btnGroupDrop2" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ $t('layers') }}
                            </button>
                            <div class="dropdown-menu" v-if="layerList.length > 0">
                                <a class="dropdown-item" v-for="(item, i) in layerList" :key="i" @click="addToArray($event, item)">
                                    <i :id="item.type" style="cursor:pointer" class="fas fa-sm fa-plus"></i>
                                    <label style="margin-bottom:0; cursor:pointer" :for="item.type">{{ item.name }}</label>
                                </a>
                            </div>
                        </div>
                        <div class="btn-group">
                            <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ $t('baseMaps') }}
                            </button>
                            <div class="dropdown-menu" v-if="typeOfMaps.length > 0">
                                <form>
                                    <a class="dropdown-item" v-for="(item, i) in typeOfMaps" :key="i" @click="addBaseMapToArray($event, item)">
                                        <i :id="item.type" style="cursor:pointer" class="fas fa-sm fa-plus"></i>
                                        <label style="margin-bottom:0; cursor:pointer" :for="item.type">{{ item.name }}</label>
                                    </a>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-row">
            <table class="table table-hover" v-if="data.baseMaps.length > 0">
                <thead>
                    <th style="width: 10%">{{ $t('type') }}</th>
                    <th style="width: 25%">{{ $t('name') }}</th>
                    <th style="width: 25%">{{ $t('url') }}</th>
                    <th style="width: 20%">{{ $t('layer') }}</th>
                    <th style="width: 15%">{{ $t('extent') }}</th>
                    <th style="width: 5%">{{ $t('opacity') }}</th>
                    <th style="width: 5%">{{ $t('visible') }}</th>
                    <th style="width: 10%">&nbsp;</th>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in data.baseMaps" :key="i">
                        <th scope="row">{{ item.type }}</th>
                        <td><input v-model="data.baseMaps[i].name" type="text" class="form-control form-control-sm"></td>
                        <td><input v-model="data.baseMaps[i].url" type="text" class="form-control form-control-sm"></td>
                        <td v-if="item.type == 'wmts'">
                            <input v-model="data.baseMaps[i].layer" type="text" class="form-control form-control-sm">
                        </td>
                        <td v-else>
                        </td>
                        <td v-if="item.type == 'image'">
                            <input v-model="data.baseMaps[i].extent" type="text" class="form-control form-control-sm">
                        </td>
                        <td v-else>
                        </td>
                        <td v-if="item.type == 'image'">
                            <input v-model="data.baseMaps[i].opacity" type="number" min="0" max="1" step="0.1" class="form-control form-control-sm">
                        </td>
                        <td v-else>
                        </td>
                        <td>
                            <div class="input-group" >
                                <div class="input-group-text">
                                    <input v-model="data.baseMaps[i].visible" type="checkbox" >
                                </div>
                            </div>
                        </td>
                        <td style="display:flex; justify-content:end">
                            <i v-if="i != 0" style="display:block; float:right; padding-top:5px; padding-right:5px; cursor:pointer" class="fas fa fa-arrow-up" @click="moveItemUp(i)"></i>
                            <i v-if="i < data.baseMaps.length-1" style="display:block; float:right; padding-top:5px; padding-right:5px; cursor:pointer" class="fas fa fa-arrow-down" @click="moveItemDown(i)"></i>
                            <i style="display:block; float:right; padding-top:5px; cursor:pointer" class="fas fa fa-trash" @click="removeBaseMapFromArray($event, i)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button type="button" class="btn btn-primary float-right" @click="saveVisualization">{{ $t('save') }}</button>
    </div>
</template>

<script>
import GroupApi from '../../../api/server/group';
import LayerApi from '../../../api/server/layer';
import VisualizationApi from '../../../api/server/visualization';

export default {
    name: "visualizationdetial",
    watch: {
        $route: "requestData",
    },
    mounted() {
        this.requestData();
    },
    methods: {
        async saveVisualization() {
            await VisualizationApi.visualizationUpdate(this.data);
            this.showSuccess()
        },
        async requestData() {
            this.data = await VisualizationApi.visualizationDetail(this.$route.params.id);
            this.groups = await GroupApi.list();
            this.layerList = await LayerApi.layerList();
        },
        addToArray(event,item) {
            event.preventDefault();
            this.data.baseMaps.push({ id: item.id, type: "layer", name: item.name ,url: "", visible: true });
        },
        removeFromArray(event, rowIndex) {
            event.preventDefault();
            if (rowIndex > -1) {
                this.data.baseMaps.splice(rowIndex, 1);
            }
        },
        addBaseMapToArray(event,item) {
            event.preventDefault();
            var tmpItem = {...item};
            this.data.baseMaps.push(tmpItem);
        },
        removeBaseMapFromArray(event, rowIndex) {
            event.preventDefault();
            if (rowIndex > -1) {
                this.data.baseMaps.splice(rowIndex, 1);
            }
        },
        moveItemUp(i) {
            const map = this.data.baseMaps[i];
            this.data.baseMaps.splice(i, 1);
            this.data.baseMaps.splice(i-1, 0, map);

            this.data.baseMaps = this.data.baseMaps;
        },
        moveItemDown(i) {
            const map = this.data.baseMaps[i];
            this.data.baseMaps.splice(i, 1);
            this.data.baseMaps.splice(i+1, 0, map);

            this.data.baseMaps = this.data.baseMaps;
        },
        getLayerName(item) {
            var foundLayer = this.layerList.find(l => l.id == item.id);
            if(foundLayer) return foundLayer.name;
            return item.id
        },
        checkedGroup(group, event) {
            if(!this.data.groups) this.data.groups = [];
            
            if(event.target.checked) {
                var index = this.data.groups.findIndex(f=>f.groupId == group.id);
                if(index < 0) {
                    this.data.groups.push({ "groupId": group.id })
                }
            } else {
                var index = this.data.groups.findIndex(f=>f.groupId == group.id);
                if(index >= 0) {
                    this.data.groups.splice(index, 1);
                }
            }
        },
        groupAtProject(group) {
            if(this.data.groups && this.data.groups.length > 0) {
                for (var i in this.data.groups){
                    if(this.data.groups[i].groupId == group.id) {
                        return true
                    }
                }
            } 
            return false;
        }
    },
    computed: {
        getSortedList() {
            return this.data.baseMaps.sort((a,b) => a.order - b.order);
        },
    },
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Visualization succesfully updated!',
            type: 'success'
        }
    },
    data() {
        return {
            groups: [],
            data: {
                groups: [],
                layers: [],
                baseMaps: [],
                projection: "",
                name: "",
                id: ""
            },
            layerList: [],
            typeOfMaps: [
                { type: "osm", name: "OSM" ,url: "", visible: true },
                { type: "wmts", name: "Ortophoto (Tirol)" ,url: "/tirolwms.xml" , layer: "gdi_ortho", visible: true },
                { type: "arcgisrest", name: "Ortophoto (Graz)", url: "https://geodaten.graz.at/arcgis/rest/services/OGD/Orthofoto_WMS/MapServer", visible: true },
                { type: "wmts", name: "Custom WMTS", url: "" , layer: "", visible: true },
                { type: "arcgisrest", name: "Custom ArcGIS REST", url: "", visible: true  },
                { type: "image", name: "Custom Image", url: "", visible: true, projection: "" }
            ],
        }
    }
};
</script>