<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('layers') }}
                        <router-link style="float: right" :to="{ name: 'layeradd'}"><i class="fas fa-lg fa-plus"></i></router-link>
                    </th>
                </tr>
            </thead>
            <tbody v-if="list.length > 0">
                <tr v-for="(item, i) in list" :key="i">
                    <td >
                        <router-link :to="{ name: 'layerdetail', params: { id: item.id }}">
                            {{ item.name }}
                        </router-link>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showDeleteLayer(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <LayerDeleteDialog v-show="isDeleteLayerModalVisible" @reload="requestData"></LayerDeleteDialog>
    </div>
</template>

<script>
import LayerApi from "../../../api/server/layer";
import LayerDeleteDialog from "./LayerDeleteDialog";

export default {
    name: "layerList",
    components: {
        LayerDeleteDialog
    },
    mounted() {
        this.requestData();
    },
    methods: {
        async requestData() {
            this.list = await LayerApi.layerList();
        },
        showDeleteLayer(item) {
            this.layerToDeleteId = item.id
            this.layerToDeleteName = item.name
            this.isDeleteLayerModalVisible = true
        },
    },
    data() {
        return {
            layerToDeleteId: "",
            layerToDeleteName: "",
            isDeleteLayerModalVisible: false,
            list: []
        };
    },
};
</script>