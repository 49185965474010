<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body ">
                        <div class="form-row"> 
                            <label for="name" required>{{ $t('name') }}</label>
                            <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('enterName')">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                            {{ $t('close') }}
                        </button>
                        <button type="button" class="btn btn-primary float-right" @click="onSubmit">{{ $t('create') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
import DataTableApi from '../../../api/server/datatable';

export default {
    methods: {
        async onSubmit() {
            let datatable = await DataTableApi.dataTableCreate(this.data);
            this.$router.push({ name: 'datatabledetail', params: { id: datatable.id } });
        },
        close() {
            this.$parent.isAddDataTableVisible = false;
        }
    },
    data() {
        return {
            data: {
                name: "",
                projection: ""
            }
        }
    }
};
</script>