<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        {{ $t('dataTableRecordUpload') }}
                    </div>
                    <div class="modal-body">
                        <b-form-file v-model="file" multiple :state="Boolean(file)" :file-name-formatter="formatNames" :placeholder="$t('chooseFile')" drop-placeholder="Drop file"></b-form-file>
                        <div v-bind:class="[hiddenAlert]">
                            <div class="alert alert-danger" role="alert">
                                {{ $t('wrongFileType') }}<br>
                                {{ $t('requiredFileType') }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-bind:class="[buttonLoading, syncLoading]" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ $t('uploading') }}
                        </button>
                        <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                            {{ $t('close') }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="uploadFiles" >
                            {{ $t('upload') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import DataTableApi from '../../../api/server/datatable';

export default {
    name: "UploadXlsxDialog",
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Files were successfully uploaded.',
            type: 'success'
        },
        showError: { 
            title: 'Error!',
            message: 'Error while uploading files, OBJECTID not found.',
            type: 'error'
        }
    },
    methods: {
        close() {
            this.file = undefined,
            this.hiddenAlert = "hidden";
            this.$parent.showUploadDataTableRecordsModal = false;
        },
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} files selected`;
        },
        async uploadFiles() {
            this.syncLoading = "";
            try {
                var upload = await DataTableApi.dataTableUpload(this.$parent.data.id, this.file);
                if(upload && upload.message && upload.message == "headerNotFound") {
                    this.showError();
                } else {
                    this.close();
                    this.showSuccess();
                    this.$emit("reload");
                }
            } catch {
                this.hiddenAlert = "";
            } finally {
                this.file = undefined;
                this.buttonLoading = "hidden";
            };
        },
    },
    data() {
        return {
            hiddenAlert: "hidden",
            buttonLoading: 'btn btn-primary',
            syncLoading: "hidden",
            file: undefined,
        };
    },
};
</script>