import request from '../utils'

const VisualizationApi = {
    visualizationList: async () => {
        const req = request.get(`/api/visualization`)
        const res = await req;
        return res.body;
    }, 
    visualizationDetail: async (visualization) => {
        const req = request.get(`/api/visualization/${visualization}`)
        const res = await req;
        return res.body;
    },
    visualizationUpdate: async (visualization) => {
        const req = request.put(`/api/visualization/${visualization.id}`).send(visualization);
        const res = await req;
        return res.body;
    },
    visualizationCreate: async (visualization) => {
        const req = request.post(`/api/visualization`).send(visualization);
        const res = await req;
        return res.body;
    },
    visualizationDelete: async (visualizationId) => {
        const req = request.delete(`/api/visualization/${visualizationId}`);
        await req;
    }
};

export default VisualizationApi