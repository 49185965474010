import request from '../utils'

const UserApi = {
    list: async () => {
        const req = request.get(`/api/user`)
        const res = await req;
        return res.body;
    },
    detail: async (user) => {
        const req = request.get(`/api/user/${user}`)
        const res = await req;
        return res.body;
    },
    setPassword: async (id, password) => {
        const req = request.put(`/api/user/${id}/resetpassword`).send(password)
        const res = await req;
        return res.body;
    },
    update: async (user) => {
        const req = request.put(`/api/user/${user.id}`).send(user);
        const res = await req;
        return res.body;
    },
    create: async (user) => {
        const req = request.post(`/api/user`).send(user);
        const res = await req;
        return res.body;
    },
    delete: async (userId) => {
        const req = request.delete(`/api/user/${userId}`);
        await req;
    }
};

export default UserApi