import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import VectorLayer from "ol/layer/Vector";
import Static from 'ol/source/ImageStatic';
import { OSM, TileArcGISRest,  } from "ol/source";
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import VectorSource from 'ol/source/Vector';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';

import { getFeaturesFromSections, defaultLineStyleFunction } from "./feature-ext";

export function createBaseLayer(layerDef, EpsgId) {
    if (layerDef.type == "osm") {
        var tilelayer = new TileLayer({ name: layerDef.name, title: layerDef.name, visible: layerDef.visible || false });
        tilelayer.setSource(new OSM());
        return tilelayer;
    } else if (layerDef.type == "arcgisrest") {
        var tilelayer = new TileLayer({ name: layerDef.name, title: layerDef.name, visible: layerDef.visible || false });
        tilelayer.setSource(new TileArcGISRest({ url: layerDef.url }));
        return tilelayer;
    } else if (layerDef.type == "image") {
        var extent = [];
        if(layerDef.extent) {
            var arrayOfExtents = layerDef.extent.split(",");
            for(var i in arrayOfExtents) {
                extent.push(parseInt(arrayOfExtents[i]));
            }
        }   
        var imageLayer = new ImageLayer({ name: layerDef.name, visible: layerDef.visible || false, type: "image", projection: layerDef.projection, opacity: parseFloat(layerDef.opacity) || 0.5 });
        imageLayer.setSource(new Static({ url: layerDef.url, projection: 'ProjectEpsg' + EpsgId , imageExtent: extent }));
        return imageLayer;
    } else if (layerDef.type == "wmts") {
        var tilelayer = new TileLayer({ name: layerDef.name, title: layerDef.name, visible: layerDef.visible || false });
        fetch(layerDef.url)
            .then(function (response) {
                return response.text();
            })
            .then(function (text) {
                var parser = new WMTSCapabilities();
                var result = parser.read(text);
                if(result) {
                    var options = optionsFromCapabilities(result, { layer: layerDef.layer, projection: "urn:ogc:def:crs:EPSG::3857" });
                    tilelayer.setSource(new WMTS(options));
                } else {
                    tilelayer = null;
                }
            });
        return tilelayer;
    }
}

export function createFeatureLayer(layer, layerDetail, dataTableDetail, sections, self) {

    let features = getFeaturesFromSections(sections, dataTableDetail.projection, "");

    let vector = new VectorLayer({
        name: layer.name,
        title: layer.name,
        id: layer.id,
        dataTableId: dataTableDetail.id,
        type: layer.type,
        visible: layer.visible,
        minResolution: parseInt(layerDetail.resolutionMin || "0"),
        maxResolution: parseInt(layerDetail.resolutionMax || "999"),
        source: new VectorSource({ features: features })
    });

    vector.on('change:visible', self.getCurrentLayerLegend);

    vector.setStyle((feature, resolution) => {
        for (let sIndex in layerDetail.symbology) {
            let symbologyDef = layerDetail.symbology[sIndex];
            let { defaultColor, defaultWidth, symbologyResolution } = symbologyDef;

            if (symbologyResolution >= resolution || !symbologyResolution) {
                if (symbologyDef.colorByAttribute) {
                    let colorAttributeValue = feature.get("properties")[symbologyDef.colorByAttribute];
                    for (let colorByAttributeIndex in symbologyDef.colorProperties) {
                        let colorByAttribute = symbologyDef.colorProperties[colorByAttributeIndex];
                        if(
                            (colorByAttribute.operator == "eq" && colorAttributeValue == colorByAttribute.operatorValue)
                            || (colorByAttribute.operator == "gt" && colorAttributeValue > parseFloat(colorByAttribute.operatorValue))
                            || (colorByAttribute.operator == "gte" && colorAttributeValue >= parseFloat(colorByAttribute.operatorValue))
                            || (colorByAttribute.operator == "lt" && colorAttributeValue < parseFloat(colorByAttribute.operatorValue))
                            || (colorByAttribute.operator == "lte" && colorAttributeValue <= parseFloat(colorByAttribute.operatorValue))
                        )
                        {
                            defaultColor = colorByAttribute.value;
                            break;
                        }
                    }
                }
                if (symbologyDef.widthByAttribute) {
                    let widthAttributeValue = feature.get("properties")[symbologyDef.widthByAttribute];
                    for (let widthByAttributeIndex in symbologyDef.widthProperties) {
                        let widthByAttribute = symbologyDef.widthProperties[widthByAttributeIndex];
                        if (widthAttributeValue == widthByAttribute.name) {
                            defaultWidth = widthByAttribute.value;
                        }
                    }
                }
                return defaultLineStyleFunction(feature, resolution, defaultColor, parseInt(defaultWidth));
            }
        }

        return defaultLineStyleFunction(feature, resolution, "#3355ff", 4);
    });

    return vector;
}