<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('datatables') }}
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showAddDataTable()" class="fas fa-sm fa-plus"></i>
                    </th>
                </tr>
            </thead>
            <tbody v-if="list.length > 0">
                <tr v-for="(item, i) in list" :key="i">
                    <td >
                        <router-link :to="{ name: 'datatabledetail', params: { id: item.id }}">
                            {{ item.name }}
                        </router-link>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showDeleteDataTable(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <DataTableDeleteDialog v-show="isModalVisible" @reload="requestData"></DataTableDeleteDialog>
        <DataTableAddDialog v-show="isAddDataTableVisible" @reload="requestData"></DataTableAddDialog>
    </div>
</template>

<script>
import DataTableApi from "../../../api/server/datatable";
import DataTableDeleteDialog from "./DataTableDeleteDialog";
import DataTableAddDialog from "./DataTableAddDialog";

export default {
    name: "dataTables",
    components: {
        DataTableDeleteDialog,
        DataTableAddDialog
    },
    mounted() {
        this.requestData();
    },
    methods: {
        async requestData() {
            this.list = await DataTableApi.dataTableList();
        },
        showDeleteDataTable(item) {
            this.dataTableToDeleteId = item.id
            this.dataTableToDeleteName = item.name
            this.isModalVisible = true
        },
        showAddDataTable() {
            this.isAddDataTableVisible = true;
        }
    },
    data() {
        return {
            dataTableToDeleteId: "",
            dataTableToDeleteName: "",
            isModalVisible: false,
            isAddDataTableVisible: false,
            list: []
        };
    },
};
</script>