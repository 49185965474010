<template>
    <div class="container py-5">
        <h2>{{ $t('visualizationCreation') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'visualizations' }">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/visualizations'">
                        {{ $t('visualizations') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    {{ $t('create') }}
                </li>
            </ol>
        </nav>
        <div class="row">
            <div class="form-group col-md-6">
                <label for="name" required>{{ $t('name') }}</label>
                <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('enterVisualizationName')"><br/>
            </div>
            <div class="form-group col-md-6">
                <label for="center">{{ $t('center') }}</label>
                <input v-model="data.center" type="text" class="form-control" id="center" placeholder="Enter coordinates">
            </div>
        </div>

        <button type="button" class="btn btn-primary float-right" @click="onSubmit">{{ $t('create') }}</button>
    </div>
</template>

<script>
import LayerApi from '../../../api/server/layer';
import VisualizationApi from '../../../api/server/visualization';

export default {
    mounted() {
        this.requestData();
    },
    methods: {
       async onSubmit() {
            this.data.domain = "";
            let visualization = await VisualizationApi.visualizationCreate(this.data);
            this.$router.push({ name: 'visualizationdetail', params: { id: visualization.id } })
        },
        async requestData() {
            this.layerList = await LayerApi.layerList();
        },
    },
    data() {
        return {
            data: {
                name: "",
                layers: [],
                baseMaps: [],
            },
            layerList: [],
            typeOfMaps: [
                { type: "osm", name: "OSM" ,url: "", visible: true },
                { type: "wmts", name: "Ortophoto (Tirol)" ,url: "/tirolwms.xml" , layer: "gdi_ortho", visible: true },
                { type: "arcgisrest", name: "Ortophoto (Graz)", url: "https://geodaten.graz.at/arcgis/rest/services/OGD/Orthofoto_WMS/MapServer", visible: true },
                { type: "wmts", name: "Custom WMTS", url: "" , layer: "", visible: true },
                { type: "arcgisrest", name: "Custom ArcGIS REST", url: "", visible: true  },
                { type: "image", name: "Custom Image", url: "", visible: true, projection: "" }
            ],
        };
    }
};
</script>
