<template>
    <div class="container py-5">
        <h2>{{ $t('layerCreation') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'visualizations' }">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/layers'">
                        {{ $t('layers') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    {{ $t('create') }}
                </li>
            </ol>
        </nav>
        <div class="row">
            <div class="form-group col-md-6">
                <label for="name" required>{{ $t('name') }}</label>
                <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('enterLayerName')">
            </div>
            <div class="form-group col-md-6">
                <label for="name" required>{{ $t('source') }}</label>
                <select v-model="data.dataTableId" type="text" class="form-control" id="name" >
                    <option disabled>{{ $t('chooseDataTable') }}</option>
                    <option v-for="(item, i) in dataTables" :key="i" v-bind:value="item.id">{{item.name}}</option>
                </select>
            </div>
        </div>
        
        <button type="button" class="btn btn-primary float-right" @click="onSubmit">{{ $t('create') }}</button>
    </div>
</template>

<script>
import DataTableApi from '../../../api/server/datatable';
import LayerApi from '../../../api/server/layer';

export default {
    mounted() {
        this.requestData();
    },
    methods: {
       async onSubmit() {
            this.data.domain = "";
            let layer = await LayerApi.layerCreate(this.data);
            this.$router.push({ name: 'layerdetail', params: { id: layer.id } })
        },
        async requestData() {
            this.dataTables = await DataTableApi.dataTableList();
        }
    },
    data() {
        return {
            data: {
                name: "",
                dataTableId: 0,
                symbology: []
            },
            dataTables: []
        };
    }
};
</script>
