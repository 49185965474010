<template>
    <div class="container py-5">
        <h2>{{ $t('userCreation') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'visualizations' }">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/users'">
                        {{ $t('users') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    {{ $t('create') }}
                </li>
            </ol>
        </nav>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="name" required>{{ $t('name') }}</label>
                <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('enterUserName')">
            </div>
            <div class="form-group col-md-6">
                <label>{{ $t('role') }}</label>
                <select id="role" v-model="data.role" class="form-control" >
                    <option disabled>{{ $t('chooseRole') }}</option>
                    <option value="Inspector" id="inspector">{{ $t('inspector') }}</option>
                    <option value="Viewer" id="viewer">{{ $t('viewer') }}</option>
                    <option value="Admin" id="admin">{{ $t('admin') }}</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="userName" required>{{ $t('loginName') }}</label>
                <input v-model="data.userName" type="text" class="form-control" id="userName" :placeholder="$t('enterLogin')">
            </div>
            <div class="form-group col-md-6">
                <label>{{ $t('team') }}</label>
                <input class="form-control" :placeholder="$t('teamName')" v-model="data.teamName"> 
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="password">{{ $t('password') }}</label>
                <input v-model="data.password" type="Password" class="form-control" id="password" :placeholder="$t('enterPassword')">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>{{ $t('group') }}</label>
                <div class="input-group">
                    <input class="form-control" :placeholder="$t('chooseGroup')" v-on:click="showModal" v-model="data.groupName" readonly> 
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" @click="showModal" type="button">{{ $t('choose') }}</button>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label style="display:block">&nbsp;</label>
                <button class="btn btn-primary" v-on:click="onSubmit" style="float:right">{{ $t('create') }}</button>
            </div>
        </div>
        <GroupToUserDialog v-show="isModalVisible" @close="closeModal" @confirm="confirmModal"></GroupToUserDialog>
    </div>
</template>

<script>
import GroupToUserDialog from "./GroupToUserDialog";
import userApi from "../../../api/server/user";
import GroupApi from "../../../api/server/group";


export default {
    components: {
        GroupToUserDialog
    },
    methods: {
       async onSubmit() {
            let content = JSON.parse(this.data.content);
                content.team = this.data.teamName;
                content.role = this.data.role;
                content.groupId = this.data.groupId;

            this.data.content = JSON.stringify(content);
            let user = await userApi.create(this.data);
            await userApi.setPassword(user.id, this.data);
            this.$router.push({ name: 'userdetail', params: { id: user.id } });
        },
        confirmModal(modalData) {
            this.modalData = modalData;
            this.data.groupName = modalData.name;
            this.data.groupId = modalData.id;
            this.closeModal();
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        }
    },
    data() {
        return {
            isModalVisible: false,
            data: {
                groupName: "",
                groupId: 0,
                userName: "",
                name: "",
                role: "",
                teamName: "",
                content: "{}",
                password: ""
            }
        };
    }
};
</script>