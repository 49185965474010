<template>
    <body class="" v-bind:class="[absoluteClass, defaultClass]">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
            <a class="navbar-brand">{{ data.name }}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0" >
                    <li class="nav-item" >
                        <a v-if="defaultClass != 'view50'" class="nav-link pointer white" @click="showTable">{{ $t('showTable') }}</a>
                        <a v-else class="nav-link pointer white" @click="hideTable">{{ $t('hideTable') }}</a>
                    </li>
                </ul>
                <router-link class="nav-link pointer white" :to="{ name: 'visualizations' }">
                    {{ $t('back') }}
                </router-link>
            </div>
        </nav>
        <div class="map-viewport clearfix">
            <div v-if="mapLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 10px;">
                <span class="spinner-border" role="status" aria-hidden="true"></span>
            </div>
            <div ref="map" class="map"></div>
            <div style="display: none">
                <div id="popup" class="ol-popup" ref="popup">
                    <div class="btn-group btn-group-toggle" data-toggle="buttons" style="width:100%">
                        <i @click="setPreviousFeature" class="pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                            </svg>
                        </i>
                        <i @click="setNextFeature" class="pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                            </svg>
                        </i>
                        <span style="margin-left:auto">{{ selectedFeatureIndex }} / {{ selectedFeatures.length}}</span>
                    </div>
                    <a href="#" id="popup-closer" class="ol-popup-closer" ref="popup-closer"></a>
                    <div id="popup-content">
                        <table class="display compact responsive nowrap" width="100%" ref="popUpDataTable">
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed-bottom p-3" >
            <nav class="pb-3">
                <div v-if="dataTablesLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 10px;">
                    <span class="spinner-border" role="status" aria-hidden="true"></span>
                </div>
                <div class="nav nav-tabs" id="nav-tab" role="tablist" ref="tableNavtabs">
                    <!-- <button style="margin-left:auto" class="btn btn-primary" @click="exportData" >Export</button> -->
                    <a style="margin-left:auto" v-bind:href="exportToXlsx" target="_blank" class="btn btn-primary">Export</a>
                </div>
            </nav>
            <div class="tab-content" ref="tableContainer"></div>
        </div>   
    </body>
</template>

<script>
import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.datatables.css';
import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import Map from "ol/Map";
import View from "ol/View";
import { createBaseLayer, createFeatureLayer } from "../../utils/layer-ext";

import { defaultLineStyleFunction } from "../../utils/feature-ext";
import { parseCoords } from "../../utils/geom-coords";
import VisualizationApi from '../../api/server/visualization';
import LayerApi from '../../api/server/layer';
import DataTableApi from '../../api/server/datatable';
import LayerSwitcher from 'ol-layerswitcher';
import Control from 'ol/control/Control';
import VectorLayer from "ol/layer/Vector";
import { Select } from 'ol/interaction';
import Overlay from 'ol/Overlay.js';

export default {
    name: "VisualizationView", 
    mounted() {
        this.getVisualization();
    },
    destroyed() {
        this.destroyMap();
        this.destroyDataTables();
        this.destroyPopUpDataTable();
    },
    watch: {
        mapLoading() {
            this.checkDataTableData();
        }
    },
    methods: {
        setCurrentDataTableToExport(dataTableId) {
            this.exportToXlsx = "/api/visualization/datatable/" + dataTableId + "/download/xlsx";
        },
        async getVisualization() {
            this.data = await VisualizationApi.visualizationDetail(this.$route.params.id);            
            this.initMap();
        },
        setPreviousFeature() {
            if(this.selectedFeatureIndex - 1 != 0) {
                this.selectedFeatureIndex -= 1;
                this.setPopupTableData();
            }
        },
        setNextFeature() {
            if(this.selectedFeatureIndex != this.selectedFeatures.length) {
                this.selectedFeatureIndex += 1;
                this.setPopupTableData();
            }
        },
        showTable() {
            let self = this;
            
            self.dataTablesShow = true;
            self.defaultClass = "view50";
            
            self.dataTablesLoading = true; // nastavení loading pro tabulku

            setTimeout(function () { // wait defaultClass = "view50"; effected 
                self.map.updateSize();
                self.checkDataTableData();
            }, 200);
        },
        hideTable() {
            var self = this;

            self.dataTablesShow = false;
            self.defaultClass = "";
            setTimeout(function () { // wait defaultClass = "view50"; effected 
                self.map.updateSize();
            }, 200);
        },
        checkDataTableData() {
            let self = this;

            // kontrola, zda má být tabulka zobrazena
            if(!self.dataTablesShow) {
                return;
            }

            // kontrola, zda se načítá mapa, musíme počkat
            if(self.mapLoading) {
                self.dataTablesLoading = true;
                return;
            }
            
            // pokud nebyla tabulka vytvořena, probíhá inicializace
            if(!self.dataTablesInitialized) {
                let tmpDataTable = {};
                self.tableData = tmpDataTable;
                // Pouze vector layers
                let layers = self.map.getLayers().getArray().filter(l => l instanceof VectorLayer);
                let o = 0;
                for(var i in layers) {
                    let lastLayer = (o == layers.length-1);
                    let layer = layers[i];
                    let dataTableId = layer.get("dataTableId");
                    let layerId = layer.get("id");
                    let layerName = layer.get("name");
                    let features = layer.getSource().getFeatures();

                    let liButton = document.createElement("button");
                    liButton.className = lastLayer ? "nav-link active" : "nav-link";
                    liButton.id = "data-table" + "_" + layerId + "_" +  dataTableId + "-tab";
                    liButton.ariaSelected = lastLayer ? "true" : "false";
                    liButton.dataset.toggle = "tab";
                    liButton.dataset.target = "#data-table" + "_" + layerId + "_" +  dataTableId;
                    liButton.type = "button";
                    liButton.role = "tab";
                    liButton.innerText = layerName;
                    liButton.addEventListener('click', function() {
                        self.setCurrentDataTableToExport(dataTableId);
                    });

                    if(lastLayer) {
                        self.setCurrentDataTableToExport(dataTableId);
                    }

                    self.$refs["tableNavtabs"].prepend(liButton);

                    let tableDiv = document.createElement("div");
                    tableDiv.className = lastLayer ? "tab-pane fade active show" : "tab-pane fade";
                    tableDiv.id = "data-table" + "_" + layerId + "_" +  dataTableId;
                    tableDiv.role = "tabpanel";
                    tableDiv.ariaLabe = "data-table" + "_" + layerId + "_" +  dataTableId + "-tab";

                    let tableElement = document.createElement("table");
                    tableElement.className = "table table-striped compact table-bordered"
                    tableElement.style ="width:100%"

                    tableDiv.appendChild(tableElement);

                    self.$refs["tableContainer"].appendChild(tableDiv);
                    let foundDataTable = self.dataTablesDetail.find(d => d.id == dataTableId);

                    let tableColumns = foundDataTable.headers.map(h => ({ data: h.name, title: h.alias, defaultContent: "", width: 100 }));
                    let tableData = features.map(s => s.get("properties"));
                    self.dataTables.push(new DataTable(tableElement, {
                        pageLength: 5,
                        lengthMenu: [ 5, 25, 50, 75, 100 ],
                        columns: tableColumns,
                        data: tableData
                    }));

                    o++;
                }
                self.dataTablesInitialized = true;
            }

            // tabulka připravena, končíme loading
            self.dataTablesLoading = false;
        },
        setPopupTableData() {
            var self = this;
            if(self.popUpDataTable) {
        
                var currentSelectedFeature = self.selectedFeatures[self.selectedFeatureIndex - 1];
                var currentSelectedFeatureProperties = currentSelectedFeature.get("properties");

                var tmpDataTable = [];
                for(var property in currentSelectedFeatureProperties) {
                    tmpDataTable.push({ name: property, value: currentSelectedFeatureProperties[property] });
                }

                self.popUpDataTable
                    .clear()
                    .rows.add(tmpDataTable)
                    .draw();
            }
        },
        async initMap() {
            var self = this;
            self.mapLoading = true;

            if(!self.$refs["map"] || !self.$refs["tableContainer"])
                return;

            // release previous map
            if (self.map) {
                self.map.setTarget(undefined);
            }

            if (self.popUpDataTable) {
                self.popUpDataTable.destroy(true);
            }

            if(self.dataTables.length) {
                self.dataTables.forEach(d => d.destroy(true));
            }
            this.dataTablesDetail = [];

            var layers = [];
            for (var i in this.data.baseMaps) {
                let layerItem = this.data.baseMaps[i];
                if (layerItem.type == "layer") {
                    
                    let layerDetail = await LayerApi.layerDetail(layerItem.id);
                    let dataTableDetail = await DataTableApi.dataTableDetail(layerDetail.dataTableId);
                    this.dataTablesDetail.push(dataTableDetail);
                    let sections = await DataTableApi.dataTableSections(layerDetail.dataTableId);

                    let vector = createFeatureLayer(layerItem, layerDetail, dataTableDetail, sections, self);
                    layers.push(vector);
                } else {
                    var epsgId = 0; // ImageLayer potřebuje definici projekce (viz inspection tool)
                    var layer = createBaseLayer(layerItem, epsgId);
                    if (layer) {
                        layers.push(layer);
                    }
                }
            }
            layers = layers.reverse();

            const overlay = new Overlay({
                element: self.$refs["popup"],
                autoPan: {
                    animation: {
                        duration: 250,
                    },
                },
            });
            self.$refs["popup-closer"].onclick = function () {
                overlay.setPosition(undefined);
                self.$refs["popup-closer"].blur();
                return false;
            };

            var view = new View({ center: parseCoords(self.data.center), zoom: 16 });
            self.map = new Map({
                target: self.$refs["map"],
                layers: layers,
                overlays: [overlay],
                view: view,
            });
         
            var styleSelectFunction = function (feature, resolution) {
                return defaultLineStyleFunction(feature, resolution, "#00ffa2", 7);
            }

            self.popUpDataTable = new DataTable(self.$refs["popUpDataTable"], {
                scrollY: '400px',
                scrollCollapse: true,
                paging: false,
                searching: false,
                info: false,
                columns: [
                    {
                        "data": "name",
                        "title": this.$t('attribute')
                    },
                    {
                        "data": "value",
                        "title": this.$t('value')
                    }
                ],
            });

            self.select = new Select({ active: false, style: styleSelectFunction });
            self.select.on('select', function (evt) {
                if (evt.selected && evt.selected.length > 0 ) { 
                    
                    self.selectedFeatures = evt.target.getFeatures().getArray();
                    self.selectedFeatureIndex = self.selectedFeatures.length;

                    self.setPopupTableData();
               
                    overlay.setPosition(evt.mapBrowserEvent.coordinate);
                } else {
                    self.selectedFeatures = [];
                    self.selectedFeatureIndex = 0;
                    overlay.setPosition(undefined);
                    self.$refs["popup-closer"].blur();
                }
            });

            self.map.addInteraction(self.select);

            const layerSwitcher = new LayerSwitcher({
                reverse: true,
                groupSelectStyle: 'group'
            });

            const legend = document.createElement('div');
            legend.className = 'ol-control';
            legend.style = 'display:block; bottom:2em; right:.5em;';
            legend.innerHTML = `<div class="btn-group dropup">
                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false"></button>
                <div class="dropdown-menu">
                    <div class="container" id="dropdown-container">
                    </div>
                </div>
            </div>`;

            self.map.addControl(new Control({ element: legend }));
            self.map.addControl(layerSwitcher);
            self.mapLoading = false;

            this.getCurrentLayerLegend();
        },
        destroyMap() {
            if (this.map) {
                this.map.setTarget(undefined);
            }
        },
        destroyPopUpDataTable() {
            if (this.popUpDataTable) {
                this.popUpDataTable.destroy();
            }
        },
        destroyDataTables() {
            if(this.dataTables.length) {
                this.dataTables.forEach(d => d.destroy(true));
            }
        },
        getPropertyOperator(value) {
            if(value=="eq") return '=';
            else if (value=="gt") return '&gt;';
            else if (value=="gte") return '&gt;=';
            else if (value=="lt") return '&lt;';
            else if (value=="lte") return '&lt;=';
            return "";
        },
        async getCurrentLayerLegend() {
            var element = document.getElementById("dropdown-container");
            var layers = this.map.getLayers().getArray();
            var filteredLayers = layers.filter(l => l.get('type') == "layer" && l.get('visible') == true);
            if(filteredLayers.length > 0) {
                var layerColors = document.createElement('div');
                for(var o in filteredLayers.reverse()) {
                    var layer = filteredLayers[o];
                    var layerId = layer.get('id');
                    var layerDetail = await LayerApi.layerDetail(layerId);

                    if(layerDetail) {
                        var resolution = this.map.getView().getResolution();
                        var validSymbology = layerDetail.symbology.find(s => s.symbologyResolution >= resolution || !s.symbologyResolution);
                        if(validSymbology) {
                            var div = document.createElement('div');
                            div.className = "col-form-label col-form-label-sm";
                            div.innerHTML = ` <h6>${layer.get('name')}</h6>`
                            layerColors.appendChild(div);

                            var defaultColorChild = document.createElement('div');
                            defaultColorChild.className = "row";
                            defaultColorChild.style = "margin: 0; flex-wrap: nowrap"
                            defaultColorChild.innerHTML = `<span class="badge col-auto col-form-label col-form-label-sm" style="background-color:${validSymbology.colorDefaultColor}; margin: 10px 0 10px 0px">&nbsp;&nbsp;&nbsp;</span>
                            <span class="col-auto col-form-label col-form-label-sm" style="padding-left:0; padding-right:0">&nbsp;</span>
                            <span class="col col-form-label col-form-label-sm" style="padding-left:0; padding-right:0">${this.$t('defaultColor')}</span>`
                            layerColors.appendChild(defaultColorChild);

                        
                            var children = document.createElement('div');
                            for(var i in validSymbology.colorProperties) {
                                var property = validSymbology.colorProperties[i];
                                var child = document.createElement('div');
                                child.className = "row";
                                child.style = "margin: 0; flex-wrap: nowrap"

                                child.innerHTML = `<span class="badge col-auto col-form-label col-form-label-sm" style="background-color:${property.value}; margin: 10px 0 10px 0px">&nbsp;&nbsp;&nbsp;</span>
                                <span class="col-auto col-form-label col-form-label-sm" style="padding-left:0; padding-right:0">&nbsp;${this.getPropertyOperator(property.operator)}&nbsp;</span>
                                <span class="col col-form-label col-form-label-sm" style="padding-left:0; padding-right:0">${property.operatorValue}</span>`

                                children.appendChild(child);
                            }
                            layerColors.appendChild(children);
                        }
                    }
                }

                element.replaceChildren(layerColors)
            } else {
                return [];
            }
        },
    },
    data() {
        return {
            data: {},
            map: null,
            mapLoading: true,
            dataTablesShow: false,
            dataTablesLoading: false,
            dataTablesInitialized: false,
            dataTables: [],
            exportToXlsx: "",
            popUpDataTable: null,
            selectedFeatureIndex: 0,
            selectedFeatures: [],
            dataTablesDetail: [],
            absoluteClass: "h-100 position-absolute",
            defaultClass: "",
            classObject: {
                "df-bottom": true,
            }
        };
    }
};
</script>

<style scoped>
.ol-popup {
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}
.ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}
.ol-popup-closer:after {
    content: "✖";
}
.map-viewport {
    position: fixed;
    top: 56px;
    right: 0;
    left: 0;
    z-index: 100;
    height: calc(100% - 56px);
}

.map-viewport .map {
    height: 100%;
    width: 100%;
}
.fixed-bottom {
    top: calc(100% + 56px);
}
.fixed-bottom table tbody {
    overflow: scroll;
}
.fixed-container {
    height: 100%;
    width: 100%;
}
.pointer {
    cursor: pointer;
}
.pointer.white {
    color: hsla(0,0%,100%,.5);
}
.view50 .map-viewport {
    height: calc(50% - 56px);
}
.view50 .fixed-bottom {
    top: 50%;
    overflow: auto;
}
</style>