<template>
	<div class="container py-5">
        <h2>{{ $t('layerDetail') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'visualizations'}">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/layers'">
                        {{ $t('layers') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">{{ data.name }}</li>
            </ol>
        </nav>
        <div class="row">
            <div class="form-group col-md-6">
                <label for="name" required>{{ $t('name') }}</label>
                <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('enterLayerName')">
            </div>
            <div class="form-group col-md-6">
                <label for="name" required>{{ $t('source') }}</label>
                <select v-model="data.dataTableId" type="text" class="form-control" id="name" >
                    <option disabled>{{ $t('chooseDataTable') }}</option>
                    <option v-for="(item, i) in dataTables" :key="i" v-bind:value="item.id">{{item.name}}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <div class="form-group col-md-6">
                <label for="staticEmail" class="col-form-label">{{ $t('resolution') }}</label>
                <div class="row">
                    <label for="resMin" class="col-sm-1 col-form-label">{{ $t('min') }}</label>
                    <div class="col-sm-2">
                        <input type="number" class="form-control" id="resMin" v-model="data.resolutionMin">
                    </div>
                    <label for="resMax" class="col-sm-1 col-form-label">{{ $t('max') }}</label>
                    <div class="col-sm-2">
                        <input type="number" class="form-control" id="resMax" v-model="data.resolutionMax">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="form-group row">
            <button class="btn btn-info" @click="addSymbologyObject()">{{ $t('addSymbology') }}</button>
        </div>
        <div v-for="(item, i) in data.symbology" :key="i" >
            <fieldset>
                <legend>
                    {{ $t('symbology') }}
                    <i style="cursor:pointer;" class="fas fa-sm fa-trash" @click="removeSymbologyObject(i)"></i>
                </legend>
                <div class="form-group row">
                    <label for="symbologyResolution" class="col-sm-2 col-form-label">{{ $t('symbologyresolution') }}</label>
                    <div class="col-sm-2">
                        <input type="number" class="form-control" id="symbologyResolution" v-model="item.symbologyResolution">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="defaultColor" class="col-sm-2 col-form-label">{{ $t('color') }}</label>
                    <div class="col-sm-2">
                        <input class="form-control" id="defaultColor" type="color" v-model="item.defaultColor" />
                    </div>
                    <label for="colorByAttribute" class="col-sm-2 col-form-label">{{ $t('colorbyattribute') }}</label>
                    <div class="col-sm-6 input-group">
                        <select class="form-control" id="colorByAttribute" v-model="item.colorByAttribute" >
                            <option v-for="(header, u) in currentDataTable" :key="u" :value="header.name" >{{header.alias}}</option>
                        </select>
                        <div class="input-group-append">
                            <button id="defineColors" type="button" class="form-control btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ $t('defineColors') }}
                            </button>
                            <div class="dropdown-menu">
                                <div style="width:300px">
                                    <div class="container">
                                        <label class="col-form-label col-form-label-sm">{{ $t('expression') }}</label>
                                        <i :id="item.name" style="cursor:pointer; float:right;padding-left:25px; margin-top:10px" class="fas fa-sm fa-plus" @click="e => addColorProperty(e, item)"></i>
                                        <label class="col-form-label col-form-label-sm" style="float:right">{{ $t('color') }}</label>
                                    </div>
                                    <div class="container" v-for="(colorDef, o) in item.colorProperties" :key="o">
                                        <div class="input-group">
                                            <select v-model="colorDef.operator" @click="e => e.stopPropagation()">
                                                <option value="eq">=</option>
                                                <option value="gt">&gt;</option>
                                                <option value="gte">&gt;=</option>
                                                <option value="lt">&lt;</option>
                                                <option value="lte">&lt;=</option>
                                            </select>
                                            <input class="form-control form-control-sm" v-model="colorDef.operatorValue"/>
                                            <input type="color" class="form-control form-control-sm" v-model="colorDef.value"/>
                                            <i style="padding-top:8px; padding-left: 5px; cursor:pointer" class="fas fa-sm fa-trash" @click="e => removeColorProperty(e, item, o)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="defaultWidth" class="col-sm-2 col-form-label">{{ $t('width') }}</label>
                    <div class="col-sm-2">
                        <input id="defaultWidth" class="form-control" type="number" v-model="item.defaultWidth" />
                    </div>
                    <label for="widthByAttribute" class="col-sm-2 col-form-label">{{ $t('widthbyattribute') }}</label>
                    <div class="col-sm-6 input-group">
                        <select class="form-control" id="widthByAttribute" v-model="item.widthByAttribute" >
                            <option v-for="(header, u) in currentDataTable" :key="u" :value="header.name" >{{header.alias}}</option>
                        </select>
                        <div class="input-group-append">
                            <button type="button" class="form-control btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ $t('defineWidth') }}
                            </button>
                            <div class="dropdown-menu">
                                <form style="width:300px">
                                    <div class="container">
                                        <label class="col-form-label col-form-label-sm">{{ $t('name') }}</label>
                                        <i :id="item.name" style="cursor:pointer; float:right;padding-left:25px; margin-top:10px" class="fas fa-sm fa-plus" @click="e => addWidthProperty(e, item)"></i>
                                        <label class="col-form-label col-form-label-sm" style="float:right">{{ $t('value') }}</label>
                                    </div>
                                    <div class="container" v-for="(oneOf, o) in item.widthProperties" :key="o">
                                        <div class="input-group ">
                                            <input class="form-control form-control-sm" v-model="oneOf.operatorValue"/>
                                            <input type="number" class="form-control  form-control-sm" v-model="oneOf.value"/>
                                            <i style="padding-top:8px; padding-left: 5px; cursor:pointer" class="fas fa-sm fa-trash" @click="e => removeWidthProperty(e, item, o)"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <button class="btn btn-primary float-right" v-on:click="saveLayer">{{ $t('save') }}</button>
    </div>
</template>

<script>
import DataTableApi from '../../../api/server/datatable';
import LayerApi from '../../../api/server/layer';

export default {
    name: "layerdetial",
    watch: {
        $route: "requestData",
    },
    mounted() {
        this.requestData();
    },
    computed: {
        currentDataTable() {
            var dataTables = this.dataTables;
            var foundDataTable = dataTables.find(d => d.id == this.data.dataTableId);
            if(foundDataTable && foundDataTable.headers) {
                return foundDataTable.headers;
            } 

            return [];
        }
    },
    methods: {
        async saveLayer() {
            await LayerApi.layerUpdate(this.data);
            this.showSuccess()
        },
        async requestData() {
            this.data = await LayerApi.layerDetail(this.$route.params.id);
            this.dataTables = await DataTableApi.dataTableList();
            console.log(this.data, this.dataTables)
        },
        addColorProperty(event, model){
            event.stopPropagation();
            if(!model.colorProperties) model.colorProperties = [];
            model.colorProperties.push({ value: "", operator: "", operatorValue: "" });
        },
        removeColorProperty(event, model, index) {
            event.stopPropagation();
            model.colorProperties.splice(index, 1);
        },
        addWidthProperty(event, model){
            event.stopPropagation();
            if(!model.widthProperties) model.widthProperties = [];
            model.widthProperties.push({ value: "", operator: "", operatorValue: "" });
        },
        removeWidthProperty(event, model, index) {
            event.stopPropagation();
            model.widthProperties.splice(index, 1);
        },
        addSymbologyObject() {
            this.data.symbology.push({ widthResolution: 0, colorResolution: 0, widthProperties: [], colorProperties: [] });
        },
        removeSymbologyObject(i) {
            this.data.symbology.splice(i, 1);
        }
    },
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Layer succesfully updated!',
            type: 'success'
        }
    },
    data() {
        return {
            data: {
                visible: true,
                datatableId: 0,
                symbology: [],
                name: "",
                id: ""
            },
            dataTables: []
        }
    }
};
</script>