<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('visualizations') }}
                        <router-link style="float: right" :to="{ name: 'visualizationadd'}"><i class="fas fa-lg fa-plus"></i></router-link>
                    </th>
                </tr>
            </thead>
            <tbody v-if="list.length > 0">
                <tr v-for="(item, i) in list" :key="i">
                    <td >
                        <router-link :to="{ name: 'visualizationdetail', params: { id: item.id }}">
                            {{ item.name }}
                        </router-link>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showDeleteVisualization(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <VisualizationDeleteDialog v-show="isDeleteVisualizationModalVisible" @reload="requestData"></VisualizationDeleteDialog>
    </div>
</template>

<script>
import VisualizationApi from "../../../api/server/visualization";
import VisualizationDeleteDialog from "./VisualizationDeleteDialog";

export default {
    name: "visualizationList",
    components: {
        VisualizationDeleteDialog
    },
    mounted() {
        this.requestData();
    },
    methods: {
        async requestData() {
            this.list = await VisualizationApi.visualizationList();
        },
        showDeleteVisualization(item) {
            this.visualizationToDeleteId = item.id
            this.visualizationToDeleteName = item.name
            this.isDeleteVisualizationModalVisible = true
        },
    },
    data() {
        return {
            visualizationToDeleteId: "",
            visualizationToDeleteName: "",
            isDeleteVisualizationModalVisible: false,
            list: []
        };
    },
};
</script>