<template>
    <div class="container py-5">
        <div class="table-responsive" >
            <div v-bind:class="['alert alert-info', hidden]" role="alert">
                Please enter your credentials <router-link to="/"><b>here</b></router-link>.
            </div>
            <table class="table table-bordered table-responsive-md">
                <thead>
                    <tr>
                        <th class="text-center">{{ $t('visualizations') }}</th>
                    </tr>
                    <tr>
                        <td style="padding:0" >
                            <input class="form-control" style="border:none" :placeholder="$t('filterByName')" v-model="filterInput" type="search">
                        </td>
                    </tr>
                    <tr v-if="groupList.length > 1">
                        <td style="padding:0" >
                            <select style="border:none" class="form-control" v-model="filterInputGroup" >
                                <option selected value="">{{$t('chooseGroup')}}</option>
                                <option v-for="(group, o) in groupList" :key="o"  v-bind:value="group.id">{{ group.name }}</option>
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody v-if="listOfVisualizations.length > 0">
                    <tr v-for="(item, i) in listOfVisualizations" :key="i">
                        <td>
                            <router-link :to="{ name: 'visualizationview', params: { id: item.id } }">
                                {{ item.name }}
                            </router-link>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        {{ $t('noAssignedVisualization') }}
                    </tr>
                </tbody>
                
            </table>
        </div>
        <div class="under-buttons">
            <div v-if="loginInfo.userrole == 'Admin' && loginInfo.isAuthenticated" style="float:left">
                <router-link to="settings/datatables" class="btn btn-secondary"> 
                    {{ $t('settings') }}
                </router-link>
            </div>
            <div class="logout">
                <button type="button" class="btn btn-secondary" @click="changeUser()" style="float:right; margin-right:20px">
                    {{ $t('logout') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import VisualizationApi from "../../api/server/visualization";
import authApi from "../../api/server/authorization"

export default {
    name: "Visualizations",
    mounted() {
        this.requestData();
    },
    computed: {
        listOfVisualizations() {
            var visualizationList = this.visualizationList;

            if (this.filterInput) {
                const lowerCaseFilter = this.filterInput.toLowerCase()
                visualizationList = visualizationList.filter((viz) => { 
                    if(viz.name.toLowerCase().includes(lowerCaseFilter))

                    return viz;
                });
            }
            if (this.filterInputGroup) {
                visualizationList = visualizationList.filter((viz) => { 
                    if(viz.groups.some(g => g.groupId == this.filterInputGroup))
                        return viz;
                });
            }

            return visualizationList;
        }
    },
    methods: {
        async requestData() {
            try {
                this.loginInfo = await authApi.auth();
                var groupId = this.loginInfo.usergroup;
                var allVisualizations = await VisualizationApi.visualizationList();

                if(this.loginInfo.userrole == "Admin") {
                    this.visualizationList = allVisualizations;
                } else {
                    for (var i in allVisualizations) {
                        
                        if (allVisualizations[i].groups) {
                            let findIndex = allVisualizations[i].groups.findIndex(g => g.groupId == groupId)
                            console.log(findIndex)
                            if (findIndex > -1) {
                                this.visualizationList.push(allVisualizations[i]);
                            } 
                        }
                    }
                }
            } catch (error) {
                // TODO: error
            }
        },
        showAlert() {
            this.hidden = "showAlertBlock";
        },
        async changeUser() {
            await authApi.logout();
            this.$router.push('/');
        }
    },
    data() {
        return {
            loginInfo: {
                isAuthenticated: false,
                usergroup: null,
                userrole: ""
            },
            hidden: "hidden",
            userrole: "",
            visualizationList: [],
            groupList: [],
            filterInput: "",
            filterInputGroup: "",
            params: "",
            name: ""
        }
    }
};
</script>

<style scoped>
    ul {
        text-align: left;
        list-style: inside;
        padding-inline-start: 0;
    }

    h2 {
        margin-top: 0px;
        display: inline-block;
    }

    button {
        display: inline-block;
    }
    .under-buttons {
        padding-top: 20px;
    }
    .table-responsive {
        max-height: calc(100vh - 70px - 3rem);
        margin: 0;
        padding: 0;
    }
    .table {
        margin-left: auto;
        margin-right: auto;
        /* width: 480px; */
    }
    .hidden {
        display: none
    }
    .showAlertBlock {
        display: block;
    }
    .settings {
        text-align: center;
    }
    .container {
        width: 100%;
        max-width: 460px;
    }
    
</style>