<template>
    <div class="container py-5">
        <h2>{{ $t('settings') }}</h2>
        <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li v-if="true" class="breadcrumb-item">
                        <router-link :to="{ name: 'visualizations' }">
                            {{ $t('home') }}    
                        </router-link>
                    </li>
                    <li v-else-if="this.$route.name === 'datatables'" class="breadcrumb-item">
                        {{ $t('datatables') }}
                    </li>
                    <li v-else-if="this.$route.name === 'layers'" class="breadcrumb-item">
                        {{ $t('layers') }}
                    </li>
                    <li v-else-if="this.$route.name === 'visualizations'" class="breadcrumb-item">
                        {{ $t('visualizations') }}
                    </li>
                    <li v-else-if="this.$route.name === 'groups'" class="breadcrumb-item">
                        {{ $t('groups') }}
                    </li>
                    <li v-else-if="this.$route.name === 'users'" class="breadcrumb-item">
                        {{ $t('users') }}
                    </li>
                </ol>
            </nav>
        <div class="row">   
            <div class="col-3">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <router-link to="/settings/datatables" id="datatables" data-toggle="pill" href="#datatables" 
                        role="tab" aria-selected="true" v-bind:class="['nav-link', activeDataTables]" >
                        {{ $t('datatables') }}
                    </router-link>
                    <router-link to="/settings/layers" id="layers" data-toggle="pill" href="#layers" 
                        role="tab" aria-selected="true" v-bind:class="['nav-link', activeLayers]" >
                        {{ $t('layers') }}
                    </router-link>
                    <router-link to="/settings/visualizations" v-bind:class="['nav-link', activeVisualizations]" id="visualizations" 
                        data-toggle="pill" href="#visualizations" role="tab" aria-controls="visualizations" aria-selected="false">
                        {{ $t('visualizations') }}
                    </router-link>
                    <router-link to="/settings/groups" v-bind:class="['nav-link', activeGroups]" id="groups" 
                        data-toggle="pill" href="#groups" role="tab" aria-controls="groups" aria-selected="false">
                        {{ $t('groups') }}
                    </router-link>
                    <router-link to="/settings/users" id="users" data-toggle="pill" href="#users" 
                        role="tab" aria-selected="true" v-bind:class="['nav-link', activeUsers]" >
                        {{ $t('users') }}
                    </router-link>
                </div>
            </div>
            <div class="col-9">
                <div class="tab-content" id="v-pills-tabContent">
                <router-view></router-view>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        this.requestData();
    },
    methods: {
        async requestData() {
            this.checkIfActive();
        },
        checkIfActive() {
            var self = this;
            if(self.$route) {
                if(self.$route.name === "users") {
                    self.activeUsers = "active";
                } else if (self.$route.name === "groups") {
                    self.activeGroups = "active";
                } else if (self.$route.name === "visualizations") {
                    self.activeVisualizations = "active";
                } else if (self.$route.name === "datatables") {
                    self.activeDataTables = "active";
                } else if (self.$route.name === "layers") {
                    self.activeLayers = "active";
                }
            }
        }
    },
    data() {
        return {
            activeUsers: "",
            activeGroups: "",
            activeVisualizations: "",
            activeDataTables: "",
            activeLayers: "",
            list: []
        };
    }
};
</script>
