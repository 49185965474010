<template>
    <div>
        <div class="form-signin">
            <img style="width:75%" class="loginLogo" src="../images/login_logo.png"/>
            <h1 class="h3 mb-3 font-weight-normal" style="text-align: center; padding-top:30px">{{ $t('pleaseSignIn') }}</h1>
            <div id="alert" class="text-align: center alert alert-danger alert-dismissible hidden" >
                {{ $t('incorrectUserOrPassword') }}
            </div>
            <label for="inputUserName" class="sr-only">{{ $t('email') }}</label>
            <input @keyup.enter="login" type="userName" id="inputUserName" class="form-control" :placeholder="$t('enterLogin')" v-model="input.userName" required autofocus />
            <label for="inputPassword" class="sr-only">{{ $t('password') }}</label>
            <input @keyup.enter="login" type="password" id="inputPassword" class="form-control" :placeholder="$t('enterPassword')" v-model="input.password" required />
            <button class="btn btn-lg btn-primary btn-block" @click="login"> {{ $t('signIn') }}</button>
        </div>
    </div>
</template>

<script>
import authApi from "../api/server/authorization";

export default {
    name: "Login",
    mounted() {
        this.getLogin();
    },
    data() {
        return {
            input: {
                userName: "",
                password: ""
            }
        };
    },
    methods: {
        async getLogin() {
            try {
                let login = await authApi.auth();
                if (login.isAuthenticated) {
                    this.$router.push({ path: "visualizations" });
                }
            } catch (error) {
                // TODO: error
            }
        },
        async login() {
            let { userName, password } = this.input;
            let login = await authApi.login(userName, password);
            if (login.succeeded) {
                this.$router.push({ path: "visualizations" });
            } else {
                let doc = document.getElementById("alert");
                doc.classList.remove("hidden");
            }
        }
    }
};
</script>

<style scoped>
    .loginLogo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .alert-dismissible {
        padding-right: 1rem;
    }
    .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: 0 auto;
    }
    .form-signin .checkbox {
        font-weight: 400;
    }
    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>