<template>
	<div class="container py-5">
		<div>
            <h2>{{ $t('datatable') }}</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'visualizations' }">
                            {{ $t('home') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="'/settings/dataTables'">
                            {{ $t('datatables') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('detail') }}
                    </li>
                </ol>
            </nav>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>{{ $t('dataTableName') }}</label>
                    <input class="form-control" v-model="data.name">
                </div>
                <div class="form-group col-md-6">
                    <label>{{ $t('projection') }}</label>
                    <div class="input-group">
                        <input v-model="data.projection" type="text" v-bind:class="['form-control']"  id="projection" placeholder="Choose projection">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#" @click="projectionType($event, '4326')">WGS 84</a>
                                <a class="dropdown-item" href="#" @click="projectionType($event, '31254')">Austria GK West</a>
                                <a class="dropdown-item" href="#" @click="projectionType($event, '31255')">Austria GK Central</a>
                                <a class="dropdown-item" href="#" @click="projectionType($event, '31256')">Austria GK East</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-10">
                    <label>{{ $t('headers') }}</label>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">{{ $t('name') }}</th>
                                <th scope="col">{{ $t('dbtype') }}</th>
                                <th scope="col">{{ $t('alias') }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="data.headers">
                            <tr v-for="(item, i) in data.headers" :key="i">
                                <td>{{item.name}}</td>
                                <td>{{item.dbtype}}</td>
                                <td><input v-model="item.alias" class="form-control" /></td>
                            </tr>
                          
                        </tbody>
                    </table>
                </div>
                <div class="form-group col-md-2">
                    <div class="btn-toolbar" role="toolbar"  style="justify-content:end">
                        <div class="btn-group mr-2" role="group">
                            <button class="btn btn-primary float-right" @click="showUploadDataTableRecords">{{ $t('upload') }}</button>
                        </div>
                        <div class="btn-group" role="group" >
                            <button class="btn btn-primary" v-on:click="saveData" style="float:right">{{ $t('save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
		</div>
        <DataTableRecordUploadDialog v-show="showUploadDataTableRecordsModal" @reload="requestData"></DataTableRecordUploadDialog>
    </div>
</template>

<script>
import DataTableApi from "../../../api/server/datatable";
import DataTableRecordUploadDialog from "./DataTableRecordUploadDialog";

export default {
    name: "dataTableDetail",
    components: {
        DataTableRecordUploadDialog
    },
    mounted() {
        this.requestData();
    },
    methods: {
        async requestData() {
            let { id } = this.$route.params;
            this.data = await DataTableApi.dataTableDetail(id);
        },
        async saveData() {
            let { id } = this.$route.params;

            await DataTableApi.dataTableUpdate(this.data);
            this.showSuccess();
        },
        showUploadDataTableRecords() {
            this.showUploadDataTableRecordsModal = true;
        },
        projectionType(event, type) { 
            event.preventDefault();
            if(type === "4326") {
                this.data.projection = 'GEOGCS["WGS 84", DATUM["World Geodetic System 1984", SPHEROID["WGS 84", 6378137.0, 298.257223563, AUTHORITY["EPSG","7030"]], AUTHORITY["EPSG","6326"]], PRIMEM["Greenwich", 0.0, AUTHORITY["EPSG","8901"]], UNIT["degree", 0.017453292519943295], AXIS["Geodetic longitude", EAST], AXIS["Geodetic latitude", NORTH], AUTHORITY["EPSG","4326"]]'
            } else if (type === "31254") {
                this.data.projection = 'PROJCS["MGI / Austria GK West",GEOGCS["MGI",DATUM["Militar_Geographische_Institute",SPHEROID["Bessel 1841",6377397.155,299.1528128,AUTHORITY["EPSG","7004"]],TOWGS84[577.326,90.129,463.919,5.137,1.474,5.297,2.4232],AUTHORITY["EPSG","6312"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4312"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",10.33333333333333],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",-5000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","31254"]]';
            } else if (type === "31255") {
                this.data.projection = 'PROJCS["MGI / Austria GK Central",GEOGCS["MGI",DATUM["Militar_Geographische_Institute",SPHEROID["Bessel 1841",6377397.155,299.1528128,AUTHORITY["EPSG","7004"]],TOWGS84[577.326,90.129,463.919,5.137,1.474,5.297,2.4232],AUTHORITY["EPSG","6312"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4312"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",13.33333333333333],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",-5000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","31255"]]';
            } else if (type === "31256") {
                this.data.projection = 'PROJCS["MGI / Austria GK East",GEOGCS["MGI",DATUM["Militar_Geographische_Institute",SPHEROID["Bessel 1841",6377397.155,299.1528128,AUTHORITY["EPSG","7004"]],TOWGS84[577.326,90.129,463.919,5.137,1.474,5.297,2.4232],AUTHORITY["EPSG","6312"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4312"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",16.33333333333333],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",-5000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","31256"]]';
            } 
        }
    },
    data() {
        return {
            name: "",
            data: {
                id: 0,
                name: "",
            },
            showUploadDataTableRecordsModal: false,
        };
    },
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'DataTable succesfully updated!',
            type: 'success'
        }
    }
};
</script>