import request from '../utils'

const LayerApi = {
    layerList: async () => {
        const req = request.get(`/api/visualization/layer`)
        const res = await req;
        return res.body;
    },
    layerDetail: async (layer) => {
        const req = request.get(`/api/visualization/layer/${layer}`)
        const res = await req;
        return res.body;
    },
    layerUpdate: async (layer) => {
        const req = request.put(`/api/visualization/layer/${layer.id}`).send(layer);
        const res = await req;
        return res.body;
    },
    layerCreate: async (layer) => {
        const req = request.post(`/api/visualization/layer`).send(layer);
        const res = await req;
        return res.body;
    },
    layerDelete: async (layerId) => {
        const req = request.delete(`/api/visualization/layer/${layerId}`);
        await req;
    }
};

export default LayerApi