import request from '../utils'

const DataTableApi = {
    dataTableList: async () => {
        const req = request.get(`/api/visualization/datatable`)
        const res = await req;
        return res.body;
    },
    dataTableDetail: async (datatable) => {
        const req = request.get(`/api/visualization/datatable/${datatable}`)
        const res = await req;
        return res.body;
    },
    dataTableUpdate: async (datatable) => {
        const req = request.put(`/api/visualization/datatable/${datatable.id}`).send(datatable);
        const res = await req;
        return res.body;
    },
    dataTableCreate: async (datatable) => {
        const req = request.post(`/api/visualization/datatable`).send(datatable);
        const res = await req;
        return res.body;
    },
    dataTableUpload: async (datatableId, files) => {
        const req = request.postMultipart(`/api/visualization/datatable/upload/${datatableId}`);
        files.forEach(file => {
            req.attach("files", file);
        });
        const res = await req;
        return res.body;
    },
    dataTableDelete: async (datatableId) => {
        const req = request.delete(`/api/visualization/datatable/${datatableId}`);
        await req;
    },
    dataTableSections: async (datatableId) => {
        const req = request.get(`/api/visualization/datatable/${datatableId}/sections`)
        const res = await req;
        return res.body;
    },
};

export default DataTableApi